import React, {useState}  from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { useNavigate } from "react-router-dom";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const Login = () => {
  let navigate = useNavigate();
    const initialLogin = {
        email: '',
        password: '',
    }
    const [loginForm, setLoginForm] = useState(initialLogin);
    const onChange = (e) =>
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        async () => {
          return await apiClient.post(`/api/login`, {
            email: loginForm.email,
            password: loginForm.password,
          });
        },
        {
          onSuccess: (res) => {
            if (res.data.message === 'unverified') {
              navigate('/verify-user');
            } else {              
            setSuccessMessage(res.data);
            triggerSuccess();
            localStorage.setItem('auth-token', res.data.access_token);
            localStorage.setItem('delox-user', JSON.stringify(res.data.user));
            localStorage.setItem('plans', JSON.stringify(res.data.plans));
            localStorage.setItem('wallets', JSON.stringify(res.data.wallets));

            window.location.href = '/dashboard';
            }
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);  
            triggerError();
          },
        }
      );
      const onSubmit = async() =>{
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      }   

  return (
    <div className="page-content my-0 py-0">
        <div className="card bg-5 card-fixed">
            <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
                <div className="text-center d-flex justify-content-center mb-4">
                  <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1653054496/rrw36logodark.png" height="40" alt="logo"/>
                </div>
                <h1 className="font-30 font-800 mb-0 text-center text-dark">Account Login</h1>
                <p className='text-center'>Fill the form below to login to your Account.</p>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                    <i className="bi bi-person-circle font-13"></i>
                    <input type="text" name="email" onChange={onChange} className="form-control rounded-xs" id="email" placeholder="sample@example.com"/>
                    <label htmlFor="email" className="text-dark">Email</label>
                    <span>(required)</span>
                </div>
                <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                    <i className="bi bi-asterisk font-13"></i>
                    <input type="password" onChange={onChange} name="password" className="form-control rounded-xs" id="password" placeholder="Password"/>
                    <label htmlFor="password" className="text-dark">Password</label>
                    <span>(required)</span>
                </div>
                <div className="form-check form-check-custom">
                    <input className="form-check-input" type="checkbox" name="type" value="" id="remember"/>
                    <label className="form-check-label font-12" htmlFor="remember">Remember this account</label>
                    <i className="is-checked color-highlight font-13 bi bi-check-circle-fill"></i>
                    <i className="is-unchecked color-highlight font-13 bi bi-circle"></i>
                </div>
                
                <div onClick={onSubmit} className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">
                  {isSendingRequest? <span>sending...</span>: <span>Login</span>}
                </div>
                <div className="row">
                    <div className="col-6 text-start">
                        <Link to="/forgot-password" className="font-14 color-green-light font-weight-bold pt-4 d-block">Forgot Password?</Link>
                    </div>
                    <div className="col-6 text-end">
                        <Link to="/register" className="font-14 color-green-light pt-4 d-block">Create Account</Link>
                    </div>
                </div>
            </div>
            <div style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1652425169/edited_15.jpg)"}} className="card-overlay rounded-0 m-0 opacity-90 gradient-green">
            </div>
        </div>

    </div>
  )
}

export default Login