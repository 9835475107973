
import React, {useEffect, useState, Fragment} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';

const ManageWithdrawals = () => {

    const [withdrawal, setwithdrawal] = useState('');   
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const getDate = (date) => new Date(date).toDateString();

    const { isLoading: isApprovingwithdrawal, mutate: approvewithdrawal } = useMutation(        
        async () => {
          return await apiClient.post(`/api/approve-withdrawal/${withdrawal.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

    const { isLoading: isDceliningwithdrawal, mutate: declinewithdrawal } = useMutation(        
        async () => {
          return await apiClient.post(`/api/decline-withdrawal/${withdrawal.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );


       const { isLoading: isDeletingwithdrawal, mutate: deletewithdrawal } = useMutation(        
        async () => {
          return await apiClient.post(`/api/delete-withdrawal/${withdrawal.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
    
    useEffect(() => {
        window.addEventListener('adminhistorywithdrawalSelected', function (e) {
            let selectedwithdrawal = localStorage.getItem('adminhistorywithdrawalSelected');
            if (selectedwithdrawal != null) {
                selectedwithdrawal = JSON.parse(selectedwithdrawal); 
                setwithdrawal(selectedwithdrawal);
            }
        }, false);
    })
  return (
    <div>
        <div id="admin-withdrawal-history-menu" className="offcanvas offcanvas-bottom offcanvas-attached">
            <div className="menu-size" style={{height:"480px"}}>
                {withdrawal !== '' &&
                <div className="content">
                <a href="/#" className="d-flex py-1 pb-4">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-person-circle font-18 color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">{withdrawal.user.email}</h5>
                        <p className="mb-0 font-11 opacity-70">{getAmount(withdrawal.amount)}</p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                    {withdrawal.status === 'pending' && <h4 className="pt-1 font-14 mb-n1 color-yellow-dark">{withdrawal.status}</h4>}
                    {withdrawal.status === 'approved' && <h4 className="pt-1 font-14 mb-n1 color-green-dark">{withdrawal.status}</h4>}
                    {withdrawal.status === 'completed' && <h4 className="pt-1 font-14 mb-n1 color-theme">{withdrawal.status}</h4>}
                    {withdrawal.status === 'declined' && <h4 className="pt-1 font-14 mb-n1 color-red-dark">{withdrawal.status}</h4>}
                    <p className="mb-0 font-11"> {getDate(withdrawal.created_at)}</p>
                    </div>
                </a>
                <div className="row">
                    <strong className="col-5 color-theme">withdrawal Amount</strong>
                    <strong className="col-7 text-end">{getAmount(withdrawal.amount)}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">withdrawal Date </strong>
                    <strong className="col-7 text-end">{getDate(withdrawal.created_at)}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">User Email</strong>
                    <strong className="col-7 text-end">{withdrawal.user.email}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div> 

                    <strong className="col-5 color-theme">withdrawal Wallet </strong>
                    <strong className="col-7 text-end color-highlight">{withdrawal.wallet_name}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>
                                      
                    <strong className="col-5 color-theme">Wallet Address </strong>
                    <strong className="col-7 text-end color-highlight">{withdrawal.wallet_address}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>
                    <div className="col-6">
                            <div onClick={approvewithdrawal} className="btn btn-s btn-full gradient-green shadow-bg shadow-bg-xs">
                            {isApprovingwithdrawal ? <span>Approving...</span>: <span>Approve</span>} 
                            </div>
                        </div>
                        <div className="col-6">
                            <div onClick={declinewithdrawal} className="btn btn-s btn-full gradient-red shadow-bg shadow-bg-xs">
                                {isDceliningwithdrawal ? <span>Declining...</span>: <span>Decline</span>} 
                            </div>
                        </div>

                        <div className="col-6 mt-2">
                            <div onClick={deletewithdrawal} className="btn btn-s btn-full gradient-red shadow-bg shadow-bg-xs">
                                {isDeletingwithdrawal ? <span>Deleting...</span>: <span>Delete</span>} 
                            </div>
                        </div>
                </div>
            </div>
                }
            </div>
        </div>
    </div>
  )
}

export default ManageWithdrawals