import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {investmentsHistory} from '../Request/Histories';
import Clock from './Clock';
import {perSecondsEarning} from '../Request/Wallets';
import {newBalance} from '../Request/Wallets';

const Investment = () => {
    let myInvestmentHistory = investmentsHistory();

    const [pendingInvestments, setPendingInvestment] = useState(0);
    const [activeInvestment, setActiveInvestment] = useState(0);
    const [completedInvestment, setcompletedInvestment] = useState(0);
    const getDate = (date) => new Date(date).toDateString();

    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const setCurrentinvestment = (investment) => {
        localStorage.setItem('historyBorrowSelected', JSON.stringify(investment));
        const investmentEvent = new Event('historyBorrowSelected');
        window.dispatchEvent(investmentEvent);
    }
   
    useEffect(() => {
        myInvestmentHistory = investmentsHistory();

        let pendingNum = 0;        
        let activeNum = 0;        
        let completedNum = 0;  

        myInvestmentHistory.forEach(investment => {
                
                if (investment.status === 'approved') {
                    activeNum += parseInt(investment.amount);
                }
                if (investment.status === 'completed') {
                    completedNum += parseInt(investment.amount);
                }
                if (investment.status === 'pending') {
                    pendingNum += parseInt(investment.amount);
                }
                if (investment.status === 'waiting approval') {
                    pendingNum += parseInt(investment.amount);
                }
            
        });
        
        setActiveInvestment(activeNum);
        setcompletedInvestment(completedNum);
        setPendingInvestment(pendingNum);
        
        if(window && window.init_template){
            window.init_template();
        }  
    }, [myInvestmentHistory, activeInvestment, completedInvestment, pendingInvestments]);

  return (
    <div id="page-content" >
        <div className="card card-style bg-5 shadow-card shadow-card-l shadow-sm" style={{height:"200px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1653128509/cardrrw3.jpg)"}}>
        <div className="card-top p-3">
            <a href="/#" onClick={ (event) => event.preventDefault() } data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></a>
        </div>
        <div className="card-center">
            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                <h1 className="font-13 my-n1">
                    <a className="color-theme" data-bs-toggle="collapse" href="#balance3" aria-controls="balance2">View Balance</a>
                </h1>
                <div className="collapse" id="balance3"><h2 className="color-theme font-26">{getAmount(newBalance())}</h2></div>
            </div>
        </div>
        <strong className="card-top no-click font-12 p-3 color-white font-monospace">investment Earnings</strong>
        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
        <div className="card-overlay "></div>
        </div>
        <div className="row text-center">
            <div className="col-6 mb-n2">
                <Link to="/invest" className="card card-style me-0" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>attach_money</i>
                            </span>
                        <h1 className="font-22 pt-3">Invest Now</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">click to start invest</p>
                    </div>
                </Link>
            </div>
            <div className="col-6 mb-n2">
                <Link to="/withdrawal" className="card card-style ms-0" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>money_off_csred</i>
                            </span>
                        <h1 className="font-22 pt-3">Withdraw</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">Withdraw Earnings</p>
                    </div>
                </Link>
            </div>			
        </div>
        {perSecondsEarning() > 0 && 
        <div className="row text-center">
            <div className="col-12 mt-n2 mb-3">
                <Clock/>
            </div>
        </div>
        }
        
<div className="card card-style">
   <div className="content mb-0">
       <div className="tabs tabs-pill" id="lend-customers">
           <div className="tab-controls rounded-m p-1 overflow-visible">
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Completed</a>
           </div>
           <div className="mt-3"></div>
           {/* <!-- Tab Bills List --> */}
        <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!pendingInvestments > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No pending investment records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myInvestmentHistory.map(investment =>(
                        investment.status === 'pending' || investment.status === 'waiting approval' &&
                        <Link key={investment.id} onClick={()=>setCurrentinvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(investment.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(investment.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-red-dark">{investment.wallet.wallet_name}</h4>
                                <p className="mb-0 font-12 opacity-50">{investment.status}</p>
                            </div>
                        </Link>
                    ))                
                               
                }
            </div>                                          
    </div>

    <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!activeInvestment > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No active investment records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myInvestmentHistory.map(investment =>(
                        investment.status === 'approved' &&
                        <Link key={investment.id} onClick={()=>setCurrentinvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(investment.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(investment.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-green-dark">{investment.wallet.wallet_name}</h4>
                                <p className="mb-0 font-12 opacity-50">{investment.status}</p>
                            </div>
                        </Link>
                    ))                
                            
                }
            </div>                                          
    </div>
    
    {/* <!-- Tab Custom Payments--> */}
    <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
        <div className="content mt-0 mb-0">
            {!completedInvestment > 0 ?
            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className="align-self-center no-click">
                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                        </div>
                        <div className="align-self-center no-click">
                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                No completed investment record avaialable
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            :
                myInvestmentHistory.map(investment =>(
                    investment.status === 'completed' &&
                    <Link key={investment.id} onClick={()=>setCurrentinvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{getAmount(investment.amount)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(investment.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-theme">{investment.wallet.wallet_name}</h4>
                            <p className="mb-0 font-12 opacity-50">{investment.status}</p>
                        </div>
                    </Link>
                ))                
            
            }
        </div>                   
        </div>
       </div>
   </div>
</div>
    </div>
  )
}

export default Investment