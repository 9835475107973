import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {referralsHistory} from '../Request/Histories';
import QRCode from "react-qr-code";
import {newRefBalance} from '../Request/Wallets';

const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
const getDate = (date) => new Date(date).toDateString();


const Referrals = () => {
let user = localStorage.getItem('delox-user');
if (user != null) {
    user = JSON.parse(user);
}
const refLink = window.location.origin + '/invitation?referral='+user.name
let myReferralHistory = referralsHistory();
const [pendingreferrals, setPendingreferral] = useState(0);
const [activereferral, setActivereferral] = useState(0);
const [completedreferral, setcompletedreferral] = useState(0);
const [copied, setCopied] = useState(false);

useEffect(() => {
    myReferralHistory = referralsHistory();
    let pendingNum = 0;        
    let activeNum = 0;        
    let completedNum = 0;  

    myReferralHistory.forEach(referral => {
            
            if (referral.status === 'approved') {
                activeNum++;
            }
            if (referral.status === 'completed') {
                completedNum ++;
            }
            if (referral.status === 'pending') {
                pendingNum ++;
            }
            if (referral.status === 'waiting approval') {
                pendingNum ++;
            }
        
    });
    
    setActivereferral(activeNum);
    setcompletedreferral(completedNum);
    setPendingreferral(pendingNum);
    
    if(window && window.init_template){
        window.init_template();
    }  
}, [myReferralHistory, activereferral, completedreferral, pendingreferrals]);
  return (
    <div id="page-content">
        <div className="card card-style bg-5 shadow-card shadow-card-l shadow-sm" style={{height:"200px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1653128509/cardrrw3.jpg)"}}>
            <div className="card-top p-3">
                <a href="/#" onClick={ (event) => event.preventDefault() } data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></a>
            </div>
            <div className="card-center">
                <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                    <h1 className="font-13 my-n1">
                        <a className="color-theme" data-bs-toggle="collapse" href="#balance3" aria-controls="balance2">View balance</a>
                    </h1>
                    <div className="collapse" id="balance3"><h2 className="color-theme font-26">{getAmount(newRefBalance())}</h2></div>
                </div>
            </div>
            <strong className="card-top no-click font-12 p-3 color-white font-monospace">Referral Balance</strong>
            <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
            <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
            <div className="card-overlay "></div>
        </div>

        <div className="card card-style px-0 bg-white">
            <div className="content text-center">
                 <div className="pb-2 font-18">Your Referral Link</div>
                <div className="mb-3">
                <CopyToClipboard text={refLink}
                  onCopy={() => setCopied(true)}>
                  <span> <span className='color-dark bg-theme p-2 border rounded-m'>{refLink}</span>  {copied ? <span className='color-green-dark ml-3 font-18 p-2 border rounded-m'>Copied!</span> :<span className="material-icons-sharp color-dark p-2 border rounded-m" style={{fontSize: "27px", transform: "translate(5px, 8px)"}}>content_copy</span>}</span>
                </CopyToClipboard>
                </div>
                <QRCode value={refLink} />
            </div>
        </div>
        <div className="card card-style">
   <div className="content mb-0">
       <div className="tabs tabs-pill" id="lend-customers">
           <div className="tab-controls rounded-m p-1 overflow-visible">
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Declined</a>
           </div>
           <div className="mt-3"></div>
           {/* <!-- Tab Bills List --> */}
        <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!pendingreferrals > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No pending referral records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myReferralHistory.map(referral =>(
                        referral.status === 'pending' &&
                        <Link key={referral.id}  data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(referral.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(referral.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-red-dark">{referral.invited.email}</h4>
                                <p className="mb-0 font-12 opacity-50">{referral.status}</p>
                            </div>
                        </Link>
                    ))                
                               
                }
            </div>                                          
    </div>

    <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!activereferral > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No active referral records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myReferralHistory.map(referral =>(
                        referral.status === 'approved' &&
                        <Link key={referral.id}  data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(referral.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(referral.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-green-dark">{referral.invited.email}</h4>
                                <p className="mb-0 font-12 opacity-50">{referral.status}</p>
                            </div>
                        </Link>
                    ))                
                            
                }
            </div>                                          
    </div>
    
    {/* <!-- Tab Custom Payments--> */}
    <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
        <div className="content mt-0 mb-0">
            {!completedreferral > 0 ?
            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className="align-self-center no-click">
                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                        </div>
                        <div className="align-self-center no-click">
                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                No completed referrals record avaialable
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            :
                myReferralHistory.map(referral =>(
                    referral.status === 'completed' &&
                    <Link key={referral.id}  data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{getAmount(referral.amount)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(referral.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-theme">{referral.invited.email}</h4>
                            <p className="mb-0 font-12 opacity-50">{referral.status}</p>
                        </div>
                    </Link>
                ))                
            
            }
        </div>                   
        </div>
       </div>
   </div>
</div>
    </div>
  )
}

export default Referrals